import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { media } from "styles/media"
import styled from "styled-components"
import Layout from "components/layout/layout"
import { StyledTitle } from "components/styled/styledTitle"
import SEO from "components/seo"

const Head = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 90px;
  & .subtitle {
    font-size: ${props => props.theme.font.s};
    line-height: 26px;
    letter-spacing: 0.3px;
    font-weight: 500;
    ${media.tablet} {
      font-size: ${props => props.theme.font.m};
      line-height: 36px;
      margin-left: 30px;
    }
  }
  ${media.tablet} {
    flex-direction: row;
    margin-top: 130px;
    align-items: flex-end;
  }
`

const ExtendStyledTitle = styled(StyledTitle)`
  ${media.pc} {
    font-size: ${props => props.theme.font.xxl};
  }
`

const Content = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 32px;
  margin-top: 32px;
  margin-bottom: 64px;
  ${media.pc} {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 40px;
    margin-top: 40px;
  }
`

const Post = styled.div`
  display: flex;
  flex-direction: column;
`

const Desc = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 8px;
  margin-top: 4px;
  ${media.pc} {
    margin-top: 8px;
  }
  & div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & p:first-child {
      font-size: 20px;
      color: ${props => props.theme.color.dark};
    }
    & p:last-child {
      font-size: 20px;
      color: ${props => props.theme.color.dark};
    }
  }

  & p {
    font-size: 14px;
    line-height: 32px;
    color: ${props => props.theme.color.dark2};
    ${media.tablet} {
      font-size: 20px;
    }
  }
`

const StyledImg = styled(Img)`
  max-width: 100%;
  height: auto;
  border-radius: 8px;
`

const Hobby = () => {
  const { allDatoCmsHobbyImage } = useStaticQuery(graphql`
    query MyQuery {
      allDatoCmsHobbyImage {
        edges {
          node {
            title
            description
            date
            image {
              fluid(maxWidth: 1200) {
                ...GatsbyDatoCmsFluid
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Hobby" />
      <div className="container">
        <Head>
          <ExtendStyledTitle>Hobby</ExtendStyledTitle>
          <p className="subtitle">Moje ostatnie zdjęcia z gór</p>
        </Head>
        <Content>
          {allDatoCmsHobbyImage.edges.map(({ node }) => (
            <Post key={node.title}>
              <StyledImg fluid={node.image.fluid} />
              <Desc>
                <div>
                  <p>
                    <strong>{node.title}</strong>
                  </p>
                  <p>{node.date} r.</p>
                </div>
                <p>{node.description}</p>
              </Desc>
            </Post>
          ))}
        </Content>
      </div>
    </Layout>
  )
}

export default Hobby
